import {combineReducers} from 'redux';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import userReducer from './slices/user';
import planReducer from './slices/plan';
import blogReducer from './slices/blog';

// ----------------------------------------------------------------------

const rootPersistConfig = {
    key: 'root',
    storage,
    keyPrefix: 'redux-',
    whitelist: [],
};

const userPersistConfig = {
    key: 'user',
    storage,
    keyPrefix: 'redux-',
};

const planPersistConfig = {
    key: 'plan',
    storage,
    keyPrefix: 'redux-',
};

const blogPersistConfig = {
    key: 'blog',
    storage,
    keyPrefix: 'redux-',
};

const rootReducer = combineReducers({
    user: persistReducer(userPersistConfig, userReducer),
    plan: persistReducer(planPersistConfig, planReducer),
    blog: persistReducer(blogPersistConfig, blogReducer),
});

export {rootPersistConfig, rootReducer};
