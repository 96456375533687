import {Suspense, lazy} from 'react';
import {Navigate, useRoutes} from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
// guards
import AuthGuard from '../guards/AuthGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks

    return (
        <Suspense fallback={<LoadingScreen isDashboard={false}/>}>
            <Component {...props} />
        </Suspense>
    );
};

export default function Router() {
    return useRoutes([
        // Dashboard Routes
        {
            path: '/',
            element: (
                <AuthGuard>
                    <DashboardLayout/>
                </AuthGuard>
            ),
            children: [
                // {element: <Navigate to={PATH_AFTER_LOGIN} replace/>, index: true},
                {
                    path: '/',
                    children: [
                        {element: <MyPlanList/>, index: true},
                        {path: 'page/:page', element: <MyPlanList/>},
                    ],
                },
                {
                    path: 'plan',
                    children: [
                        {element: <MyPlanList/>, index: true},
                        {path: ':planId', element: <MyPlanDetail/>},
                        {path: ':planId/update-plan', element: <MyPlanAddNew/>},
                        {path: ':planId/reports', element: <MyPlanReport/>},
                        {path: ':planId/add-transaction', element: <MyPlanAddTransaction/>},
                        {path: ':planId/:planTransactionId/update-transaction', element: <MyPlanAddTransaction/>},
                        {path: ':planId/add-member', element: <MyPlanAddMember/>},
                        {path: ':planId/add-member-multiple', element: <MyPlanAddMemberMultiple/>},
                        {path: ':planId/:userId/update-member', element: <MyPlanAddMember/>},
                        {path: ':planId/add-fund', element: <MyPlanAddFund/>},
                        {path: ':planId/:planFundId/update-fund', element: <MyPlanAddFund/>},
                        {path: ':planId/add-sponsor', element: <MyPlanAddSponsor/>},
                        {path: ':planId/:planFundId/update-sponsor', element: <MyPlanAddSponsor/>},
                        {path: ':planId/transactions', element: <MyPlanReport/>},
                        {path: ':planId/members', element: <MyPlanReport/>},
                        {path: ':planId/funds', element: <MyPlanReport/>},
                        {
                            path: 'joins',
                            children: [
                                {element: <MyPlanList/>, index: true},
                                {path: 'page/:page', element: <MyPlanList/>},
                            ]
                        },
                        {
                            path: 'owners',
                            children: [
                                {element: <MyPlanList/>, index: true},
                                {path: 'page/:page', element: <MyPlanList/>},
                            ]
                        },
                        {
                            path: 'payments',
                            children: [
                                {element: <MyPlanList/>, index: true},
                                {path: 'page/:page', element: <MyPlanList/>},
                            ]
                        },
                        {path: 'new', element: <MyPlanAddNew/>},
                    ],
                },
                {
                    path: 'blog',
                    children: [
                        {element: <BlogList/>, index: true},
                        {path: ':blogId', element: <BlogDetail/>},
                        {path: 'new', element: <BlogNew/>},
                    ],
                },
                {
                    path: 'tag',
                    children: [
                        {path: ':tagSlug', element: <BlogList/>},
                    ],
                },
                {
                    path: 'user',
                    children: [
                        {element: <Navigate to="/user/profile" replace/>, index: true},
                        {path: 'profile', element: <UserProfile/>},
                        {path: 'forgot', element: <UserForgotPassword/>},
                        {path: 'account', element: <UserAccount/>},
                        {path: 'login', element: <UserLogin/>},
                    ],
                },
            ],
        },

    ])
        ;
}


// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/GeneralApp')));
// USER
const UserProfile = Loadable(lazy(() => import('../pages/user/UserProfile')));
const UserAccount = Loadable(lazy(() => import('../pages/user/UserAccount')));
const UserForgotPassword = Loadable(lazy(() => import('../pages/user/UserForgotPassword')));
const UserLogin = Loadable(lazy(() => import('../pages/user/UserLogin')));

// MY PLAN
const MyPlanList = Loadable(lazy(() => import('../pages/plan/MyPlanList')));
const MyPlanDetail = Loadable(lazy(() => import('../pages/plan/MyPlanDetail')));
const MyPlanReport = Loadable(lazy(() => import('../pages/plan/MyPlanReport')));
const MyPlanAddFund = Loadable(lazy(() => import('../pages/plan/MyPlanAddFund')));
const MyPlanAddSponsor = Loadable(lazy(() => import('../pages/plan/MyPlanAddSponsor')));
const MyPlanAddMember = Loadable(lazy(() => import('../pages/plan/MyPlanAddMember')));
const MyPlanAddMemberMultiple = Loadable(lazy(() => import('../pages/plan/MyPlanAddMemberMultiple')));
const MyPlanAddTransaction = Loadable(lazy(() => import('../pages/plan/MyPlanAddTransaction')));
const MyPlanAddNew = Loadable(lazy(() => import('../pages/plan/MyPlanAddNew')));

// BLOG
const BlogList = Loadable(lazy(() => import('../pages/blog/BlogList')));
const BlogDetail = Loadable(lazy(() => import('../pages/blog/BlogDetail')));
const BlogNew = Loadable(lazy(() => import('../pages/blog/BlogNew')));
