// ----------------------------------------------------------------------

function path(root, sublink) {
    return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '';

// ----------------------------------------------------------------------

function getUrlWithShareToken(url, shareToken) {
    if (shareToken) {
        return `${url}?shareToken=${shareToken}`;
    }
    return url
}
export const PATH_PAGE = {
    root: ROOTS_DASHBOARD,
    general: {
        app: path(ROOTS_DASHBOARD, '/'),
    },
    user: {
        root: path(ROOTS_DASHBOARD, '/user'),
        forgot: path(ROOTS_DASHBOARD, '/user/forgot'),
        login: path(ROOTS_DASHBOARD, '/user/login'),
        profile: path(ROOTS_DASHBOARD, '/user/profile'),
        account: path(ROOTS_DASHBOARD, '/user/account'),
    },
    plan: {
        root: path(ROOTS_DASHBOARD, '/plan'),
        new: path(ROOTS_DASHBOARD, '/plan/new'),
        joinList: path(ROOTS_DASHBOARD, '/plan/joins'),
        owners: path(ROOTS_DASHBOARD, '/plan/owners'),
        needPayment: path(ROOTS_DASHBOARD, '/plan/payments'),
        funds: (planId) => path(ROOTS_DASHBOARD, `/plan/${planId}/funds`),
        addFund: (planId) => path(ROOTS_DASHBOARD, `/plan/${planId}/add-fund`),
        editFund: (planId, planFundId) => path(ROOTS_DASHBOARD, `/plan/${planId}/${planFundId}/update-fund`),
        addSponsor: (planId) => path(ROOTS_DASHBOARD, `/plan/${planId}/add-sponsor`),
        editSponsor: (planId, planFundId) => path(ROOTS_DASHBOARD, `/plan/${planId}/${planFundId}/update-sponsor`),
        members: (planId) => path(ROOTS_DASHBOARD, `/plan/${planId}/members`),
        addMember: (planId) => path(ROOTS_DASHBOARD, `/plan/${planId}/add-member`),
        addMemberMultiple: (planId) => path(ROOTS_DASHBOARD, `/plan/${planId}/add-member-multiple`),
        editMember: (planId, planUserId) => path(ROOTS_DASHBOARD, `/plan/${planId}/${planUserId}/update-member`),
        transactions: (planId) => path(ROOTS_DASHBOARD, `/plan/${planId}/transactions`),
        addTransaction: (planId) => path(ROOTS_DASHBOARD, `/plan/${planId}/add-transaction`),
        editTransaction: (planId, planTransactionId) => path(ROOTS_DASHBOARD, `/plan/${planId}/${planTransactionId}/update-transaction`),
        reports: (planId, planShareToken) => path(ROOTS_DASHBOARD, getUrlWithShareToken(`/plan/${planId}/reports`, planShareToken)),
        detail: (planId, planShareToken) => path(ROOTS_DASHBOARD, getUrlWithShareToken(`/plan/${planId}`, planShareToken)),
        editPlan: (planId) => path(ROOTS_DASHBOARD, `/plan/${planId}/update-plan`),
    },
    blog: {
        root: path(ROOTS_DASHBOARD, '/blog'),
        new: path(ROOTS_DASHBOARD, '/blog/new'),
        detail: (blogId) => path(ROOTS_DASHBOARD, `/blog/${blogId}`),
    },
    category: {
        root: path(ROOTS_DASHBOARD, '/tag'),
        list: (tagSlug) => path(ROOTS_DASHBOARD, `/tag/${tagSlug}`),
    }
};

