import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";

import LoadingScreen from '../components/LoadingScreen';
import {isValidToken} from "../utils/jwt";
import {getProfile, postLoginAnonymous} from "../redux/slices/user";

import {PATH_PAGE} from '../routes/paths';
import {useSnackbar} from "notistack";
// ----------------------------------------------------------------------

AuthGuard.propTypes = {
    children: PropTypes.node,
};

export default function AuthGuard({children}) {

    const {enqueueSnackbar} = useSnackbar();
    const dispatch = useDispatch();
    const {accessToken, isLoading, user, error} = useSelector((state) => state.user);
    const {pathname} = useLocation();
    const [requestedLocation, setRequestedLocation] = useState(null);
    const needLogin = [
        PATH_PAGE.user.account,
        PATH_PAGE.user.profile,
        PATH_PAGE.plan.new
    ]
    const checkAccessTokens = async (isFirst = undefined) => {
        const saveAccessToken = window.localStorage.getItem('accessToken');
        if (saveAccessToken && isValidToken(saveAccessToken)) {
            if (await dispatch(getProfile(saveAccessToken))) {
                return
            }
        }

        window.localStorage.removeItem('accessToken');
        let respLogin = await dispatch(postLoginAnonymous());
        if (respLogin?.user) {
            console.log('login success', respLogin)
        } else {
            console.log('login failed', respLogin);
            if (isFirst) {
                window.localStorage.removeItem('accessToken');
                alert("Hệ thống đang gặp lỗi. Vui lòng xóa cache và thử lại", {variant: "error"});
            }
        }
    }
    const initialize = async (force) => {
        if (isLoading && !force) {
            console.log('not force, waiting for loading', force, isLoading)
            return
        }
        if (force) {
            window.localStorage.removeItem('accessToken');
            checkAccessTokens();
        }
        console.log('initialize')
    }

    useEffect(() => {
        checkAccessTokens(true);
    }, []);


    if (!isValidToken(accessToken)) {
        console.log('not valid token')
        initialize();
        return <LoadingScreen/>;
    } else {
        if (user?.time && new Date().getTime() - user?.time > 1000 * 60 * 60) {
            initialize(true);
            return <LoadingScreen/>;
        }
    }
    if (!user && needLogin.indexOf(pathname) !== -1) {
        return <Navigate to={"/"} replace={true}/>;
    }
    if (requestedLocation && pathname !== requestedLocation) {
        setRequestedLocation(null);
        return <Navigate to={requestedLocation}/>;
    }

    return <>{children}</>;
}
