import {createSlice} from '@reduxjs/toolkit';
import {dispatch} from '../store';
import {makeGetApi, makePostApi, makePutApi} from "../../api";

// ----------------------------------------------------------------------
const defaultDetail = {};
const initialState = {
    isLoading: false,
    error: null,
    blogDetail: defaultDetail,
};

const slice = createSlice({
    name: 'blog',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        stopLoading(state) {
            state.isLoading = false;
        },
        startMount(state) {
            state.isLoading = false;
            state.error = null;
        },
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload
            state.isLoading=  false
        },
        resetError(state) {
            state.error = null
            state.isLoading = false
        },
        getBlogDetailSuccess(state, action) {
            state.isLoading = false;
            state.blogDetail = action.payload;
            state.error = null;
        },
        updateBlogDetailSuccess(state, action) {
            state.isLoading = false;
            state.blogDetail = action.payload;
            state.error = null;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    getBlogDetailSuccess,
} = slice.actions;

const defaultError = {
    message: "Lỗi kết nối hệ thống"
}
const convertError = (error) => {
    if (!error) {
        return defaultError;
    }
    if (error?.message) {
        return error;
    } else {
        return {
            message: error.toString()
        };
    }
}

// ----------------------------------------------------------------------
export function startReMount() {
    return async (dispatch) => {
        dispatch(slice.actions.startMount());
    };
}

export function resetError() {
    return async (dispatch) => {
        dispatch(slice.actions.resetError());
    };
}

export function getBlogDetail(blogId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            let url = `/api/v1/blog/${blogId}`;
            const response = await makeGetApi(url);
            let blog = response.data.data;
            dispatch(slice.actions.getBlogDetailSuccess(blog));
            return blog;
        } catch (error) {
            dispatch(slice.actions.hasError(error || defaultError));
        }
    };
}
export function addBlog(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await makePostApi(`/api/v1/blogs`, data);
            dispatch(slice.actions.stopLoading());
            return response.data;
        } catch (error) {
            dispatch(slice.actions.hasError(convertError(error)));
        }
    };
}

export function updateBlog(blogID, data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await makePutApi(`/api/v1/blog/${blogID}`, data);
            dispatch(slice.actions.stopLoading());
            dispatch(getBlogDetail(blogID));
            return response.data;
        } catch (error) {
            dispatch(slice.actions.hasError(convertError(error)));
        }
    };
}