import {createSlice} from '@reduxjs/toolkit';
import {dispatch} from '../store';
import {makeCallApiWithoutToken, makeDeleteApi, makeGetApi, makePostApi, makePutApi} from "../../api";

// ----------------------------------------------------------------------
const defaultPlanDetail = {
    funds: [],
    transactions: [],
    users: [],
};
const initialState = {
    isLoading: false,
    error: null,
    planDetail: defaultPlanDetail,
};

const slice = createSlice({
    name: 'plan',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        stopLoading(state) {
            state.isLoading = false;
        },
        startMount(state) {
            state.isLoading = false;
            state.error = null;
        },
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload
            state.isLoading = false
        },
        hasPlanError(state, action) {
            state.planDetail = defaultPlanDetail
            state.error = action.payload
            state.isLoading = false
        },
        resetError(state) {
            state.error = null
            state.isLoading = false
        },
        getPlanDetailSuccess(state, action) {
            state.isLoading = false;
            state.planDetail = action.payload;
            state.error = null;
        },
        updatePlanDetailSuccess(state, action) {
            state.isLoading = false;
            state.planDetail = action.payload;
            state.error = null;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    getPlanDetailSuccess,
} = slice.actions;

const defaultError = {
    message: "Lỗi kết nối hệ thống"
}
const convertError = (error) => {
    if (!error) {
        return defaultError;
    }
    if (error?.message) {
        return error;
    } else {
        return {
            message: error.toString()
        };
    }
}

// ----------------------------------------------------------------------
export function startReMount() {
    return async (dispatch) => {
        dispatch(slice.actions.startMount());
    };
}

export function resetError() {
    return async (dispatch) => {
        dispatch(slice.actions.resetError());
    };
}

export function getPlanDetail(planID, planShareToken) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            let url = `/api/v1/plan/${planID}`;
            if (planShareToken) {
                url += `?shareToken=${planShareToken}`;
            }
            const response = await makeGetApi(url);
            let plan = response.data.data;
            dispatch(slice.actions.getPlanDetailSuccess(plan));
            return plan;
        } catch (error) {
            dispatch(slice.actions.hasPlanError(error || defaultError));
        }
    };
}

export function addPlanFund(planID, data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await makePostApi(`/api/v1/plan/${planID}/funds`, data);
            await dispatch(getPlanDetail(planID));
            return response.data;
        } catch (error) {
            dispatch(slice.actions.hasError(convertError(error)));
        }
    };
}

export function updatePlanFund(fundID, data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await makePutApi(`/api/v1/plan/fund/${fundID}`, data);
            return response.data;
        } catch (error) {
            dispatch(slice.actions.hasError(convertError(error)));
        }
    };
}


export function addPlanMember(planID, data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await makePostApi(`/api/v1/plan/${planID}/users`, data);
            await dispatch(getPlanDetail(planID));
            return response.data;
        } catch (error) {
            dispatch(slice.actions.hasError(convertError(error)));
        }
    };
}

export function addPlanTransaction(planID, data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await makePostApi(`/api/v1/plan/${planID}/transactions`, data);
            await dispatch(getPlanDetail(planID));
            return response.data;
        } catch (error) {
            dispatch(slice.actions.hasError(convertError(error)));
        }
    };
}


export function updatePlanTransaction(planTransactionID, data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await makePutApi(`/api/v1/plan/transaction/${planTransactionID}`, data);
            dispatch(slice.actions.stopLoading());
            return response.data;
        } catch (error) {
            dispatch(slice.actions.hasError(convertError(error)));
        }
    };
}

export function deletePlanTransaction(planTransactionID) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await makeDeleteApi(`/api/v1/plan/transaction/${planTransactionID}`);
            dispatch(slice.actions.stopLoading());
            return response.data;
        } catch (error) {
            dispatch(slice.actions.hasError(convertError(error)));
        }
    };
}

export function addPlan(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await makePostApi(`/api/v1/plans`, data);
            dispatch(slice.actions.stopLoading());
            return response.data;
        } catch (error) {
            dispatch(slice.actions.hasError(convertError(error)));
        }
    };
}

export function updatePlan(planID, data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await makePutApi(`/api/v1/plan/${planID}`, data);
            dispatch(slice.actions.stopLoading());
            dispatch(getPlanDetail(planID));
            return response.data;
        } catch (error) {
            dispatch(slice.actions.hasError(convertError(error)));
        }
    };
}

export function sharePlan(planID) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            let url = `/api/v1/plan/${planID}/share`;
            const response = await makePostApi(url);
            dispatch(slice.actions.stopLoading());
            return response.data;
        } catch (error) {
            return error
        }
    }
}

export function createTransaction(planId, amount, transaction_code, userId = undefined, qr_type = 'payos') {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            let url = `/api/v1/qrcode/transaction`;
            const response = await makePostApi(url, {
                "amount": parseInt(amount),
                "planId": planId,
                "transactionCode": transaction_code,
                "qrType": qr_type,
                "forUserId": userId,
            });
            dispatch(slice.actions.stopLoading());
            return response.data;
        } catch (error) {
            return error
        }
    }
}

export function createQRPayment(amount, transaction_code, qr_type) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            let url = `https://plan.tranvu.info/api/v1/payment/qr_code`;
            const response = await makeCallApiWithoutToken(url, 'PUT', {
                data: {
                    "amount": parseInt(amount),
                    "bill_code": transaction_code,
                    "qr_type": qr_type,
                },
            });
            dispatch(slice.actions.stopLoading());
            return response.data;
        } catch (error) {
            return error
        }
    }
}

export function checkTransaction(transaction_code) {
    return async () => {
        try {
            let url = `/api/v1/qrcode/transaction`;
            const response = await makeGetApi(url, {
                "transaction": transaction_code,
            });
            return response.data;
        } catch (error) {
            return error
        }
    }
}

export function deletePlanMember(planID, data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await makeDeleteApi(`/api/v1/plan/${planID}/users`, data);
            await dispatch(getPlanDetail(planID));
            return response.data;
        } catch (error) {
            dispatch(slice.actions.hasError(convertError(error)));
        }
    };
}