// routes
import {PATH_PAGE} from '../../../routes/paths';
import Iconify from "../../../components/Iconify";

// ----------------------------------------------------------------------

const navConfig = [
    {
        subheader: 'management',
        items: [
            {
                title: 'Trang chủ',
                path: PATH_PAGE.root,
                icon: <Iconify icon={'bx:bxs-home'}/>,
            },
            {
                title: 'Khám phá',
                path: PATH_PAGE.blog.root,
                icon: <Iconify icon={'arcticons:maps'}/>,
                // children: [
                //     {title: 'Trải nghiệm', path: "https://food.tranvu.info"},
                // ],
            },
            // PLAN
            {
                title: 'Kế hoạch',
                path: PATH_PAGE.plan.root,
                icon: <Iconify icon={'pajamas:planning'}/>,
                children: [
                    // {title: 'Tìm thành viên', path: PATH_PAGE.plan.joinList},
                    {title: 'Bạn tham gia', path: PATH_PAGE.plan.joinList},
                    {title: 'Bạn khởi động', path: PATH_PAGE.plan.owners},
                    { title: 'Cần thanh toán ', path: PATH_PAGE.plan.needPayment },
                ],
            },
            {
                title: 'Liên kết',
                icon: <Iconify icon={'mdi:link-box-variant-outline'}/>,
                children: [
                    {
                        title: 'Đặt món chung',
                        icon: <Iconify icon={'ic:twotone-food-bank'}/>,
                        path: "https://food.tranvu.info"
                    },
                    {
                        title: 'Nghe truyện',
                        icon: <Iconify icon={'simple-icons:audiomack'}/>,
                        path: "https://vradio.me"
                    },
                    {
                        title: 'Viết lách',
                        icon: <Iconify icon={'jam:blogger-square'}/>,
                        path: "https://blog.tranvu.info"
                    },
                ],
            },

        ],
    }
];

export default navConfig;
