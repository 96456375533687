import PropTypes from 'prop-types';
import {Link as RouterLink} from 'react-router-dom';
import {Box} from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
    disabledLink: PropTypes.bool,
    sx: PropTypes.object,
};

export default function Logo({disabledLink = false, sx}) {
    const logo = (
        <Box sx={{width: 200, height: 40, ...sx}}>
            <img src={"/static/logo.png"} alt="logo" sx={{marginTop: "-20px", width: "100%"}}/>
        </Box>
    );

    if (disabledLink) {
        return <>{logo}</>;
    }

    return <RouterLink to="/">{logo}</RouterLink>;
}
